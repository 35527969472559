import React from 'react'
import Loader from 'react-dots-loader'
import 'react-dots-loader/index.css'

function DotsLoader() {
  return (
    <div className="loader-container">
        <Loader 
            height="80" 
            width="80" 
            radius="9"
            color="#05206224" 
            ariaLabel="three-dots-loading"
            visible={true}
        />
    </div>
  )
}

export default DotsLoader