import React from 'react';
import thirdStepRemortgageModal from '../../assets/images/illustrations/step-3-remortgage-modal.png';
import ModalHeading from './ModalHeading';
import { BUTTON_MODAL } from '../../constants';
import Button from '../Button';
import { SecondaryDescription, SecondaryTitle } from '../Typography';
import useWindow from '../../hooks/useWindow';

function DealsModal({ handleClick }) {
  const { isMobile } = useWindow()

  return (
    <div className="deals-modal">
      <SecondaryTitle>
        Great you can now compare 
        {!isMobile ? <br /> : " "}
        the latest deals for free!
      </SecondaryTitle>
      <img src={thirdStepRemortgageModal} />
      <div className="deals-modal__content">
        <SecondaryDescription>
            To help you talk through your options a mortgage advisor from a regulated  
            {!isMobile ? <br /> : " "}
            firm will contact you (usually within the next 24 business hours). Please  
            {!isMobile ? <br /> : " "}
            check your emails and expect a call from them. 
        </SecondaryDescription>
        <SecondaryTitle>
            Why are we connecting you to an advisor?
        </SecondaryTitle>
        <SecondaryDescription>
            By speaking to an advisor at a regulated firm, they can talk through your 
            {!isMobile ? <br /> : " "}
            options to help you secure your next deal and do the heavy lifting for you.
            {!isMobile ? <br /> : " "}
            We will only use your contact details to discuss your mortgage needs, nothing else.
        </SecondaryDescription>
      </div>
      <div className="info-modal__button-container">
        <Button
          buttonText="View Deals"
          height="56px"
          size={BUTTON_MODAL}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
}

export default DealsModal;
