import React from 'react';
import SEO from '../components/SEO';
import editIcon from '../assets/images/icons/other/edit-icon.png';
import { MainTitle, SecondaryDescription } from '../components/Typography';
import imageHeading from '../assets/images/illustrations/template-deals-main.png'
import SectionWrapper from '../components/CommonSections/SectionWrapper';
import useWindow from '../hooks/useWindow';
import Deals from '../components/Deals';
import BasicModal from '../components/Modals/BasicModal';
import MortgageDetailsSection from '../components/CommonSections/MortgageDetails';
import { useState } from 'react';
import DealsHeading from '../components/CommonSections/DealsHeading';
import ModalsFlow from '../components/Modals/ModalsFlow';
import ContactWidget from '../components/ContactWidget';
import DealsModal from '../components/Modals/DealsModal';
import useSSR from '../hooks/useSSR';
import { useEffect } from 'react';

const navLinks = [
  {
    title: 'Home',
    leadsTo: '/',
  },
  {
    title: 'Remortgage',
    leadsTo: '/mortgages/remortgage',
  },
  {
    title: 'View Deals',
    leadsTo: null,
    isActive: true,
  },
];

function viewDealsPage() {
  const isSSR = useSSR()

  const { isDesktop, isTablet, isMobile } = useWindow();
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [mortgageDetails, setMortgageDetails] = useState(null)
  const [dealsDropdown, setDealsDropdown] = useState({})
  const [dealsState, setDealsState] = useState({})
  const [templatePage, setTemplatePage] = useState(null)

  useEffect(() => {
    if(isSSR === null) return

    if(!isSSR) {
      const state = JSON.parse(localStorage.getItem("dealsState"))
      if(state) {
        setTemplatePage(true)
        setDealsDropdown(state?.useDropdown)
        setDealsState(state)
      }
      else {
        setTemplatePage(false)
      }
    }
  }, [isSSR])

  return (
    <>
      <SEO title="View Deals" />
      <div className="common-page">
        <div className="deals-section">
          <DealsHeading
            links={dealsState?.customLinks || navLinks}
            image={dealsState?.useImage && imageHeading}
            leftContent={
              <>
                {!isMobile ? (
                  <MainTitle>
                    Best mortgages based on borrowing
                    {isTablet && <br />}
                    <span>{mortgageDetails?.borrowing_amount}</span>
                    {isDesktop && <br />}
                    over
                    {isTablet && <br />}
                    <span>{mortgageDetails?.borrowing_period}</span>
                  </MainTitle>
                ) : (
                  <MainTitle>Best mortgages based on borrowing {mortgageDetails?.borrowing_amount} over {mortgageDetails?.borrowing_period}</MainTitle>
                )}
                {!dealsState?.useImage && (
                  <div className="deals-section__edit" onClick={() => setEditModal(true)}>
                    <img src={editIcon} />
                    <SecondaryDescription>Edit Calculator</SecondaryDescription>
                  </div>
                )}
              </>
            }
          />
            <div className={dealsState?.useImage && "deals-section__deals"}>
              <SectionWrapper>
                <Deals 
                  editModal={editModal} 
                  setMortgageDetails={setMortgageDetails}
                  leadToHighlights={!dealsState?.useImage}
                  templatePage={templatePage}
                />
              </SectionWrapper>
            </div>
          <BasicModal
            isOpen={editModal}
            isBottom={isMobile}
            closeHandler={() => setEditModal(false)}
            customClassName="ReactModal__Content-deal"
            style={{
              overlay: {
                height: '100vh',
                overflow: 'scroll',
              },
              content: {
                maxHeight: isMobile ? '700px' : null,
                top: '90%',
              },
            }}
          >
            <MortgageDetailsSection withoutHeading customFormHandler={() => setEditModal(false)} prefill/>
          </BasicModal>
          <ContactWidget
            handleClick={() => setIsContactModalOpen(true)}
            title="Speak to a mortgage expert for FREE"
          />
          <ModalsFlow
            isFirstOpened={isContactModalOpen}
            setIsFirstOpened={(state) => setIsContactModalOpen(state)}
            firstModalIndex={1}
            usePureCalendlyWidget
          />
          {dealsState?.useDropdown && (
            <BasicModal 
              isOpen={dealsDropdown}
              isBottom={isMobile}
              closeHandler={() => setDealsDropdown(false)}
              scroll
              style={{
                content: {
                  height: isMobile ? '600px' : '803px',
                },
              }}
            >
              <DealsModal handleClick={() => setDealsDropdown(false)}/>
            </BasicModal>
          )}
        </div>
      </div>
    </>
  );
}

export default viewDealsPage;
